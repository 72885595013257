import {
  getPreferenceValues,
  getHasPreferenceValue,
} from '@/services/system-evaluation';

/* eslint-disable import/prefer-default-export */
// eslint-disable-next-line arrow-body-style
export const usePreferenceValues = () => {
  return {
    getHasPreferenceValue,
    getPreferenceValues,
  };
};
