<template>
  <div>
    <modal
      :enabled="sheetEnabled"
      type="sheets"
      title="Edit preference"
      primary-button-text="Save"
      secondary-button-text="Cancel"
      :primary-button-disabled="primaryButtonDisabled"
      :secondary-button-disabled="false"
      @primaryClicked="onSaveClicked"
      @secondaryClicked="onCancelClicked"
      @closeModal="onCloseModal"
    >
      <label
        class="user-preference__textarea-label"
      >
        {{ preference.name }}
        <label
          class="user-preference__textarea-clear"
          @click="onClearClicked"
        >
          Clear
        </label>
      </label>
      <textarea
        v-if="baseType === 'String'"
        v-model="inputString"
        class="user-preference__textarea"
        type="text"
        placeholder="No value"
        :rows="10"
      />
      <option-dropdown
        v-if="baseType === 'Boolean'"
        :items="[true, false]"
        :selected-item="inputBoolean"
        default-text="No value"
        @itemSelected="onBooleanSelected"
      />
      <Datepicker
        v-if="baseType === 'Date'"
        v-model="inputDate"
        :placeholder="String(inputDate) === 'null' ? 'No value' : String(inputDate) || 'No value'"
        :text-input="true"
        :text-input-options="{ enterSubmit: true, tabSubmit: true }"
        :clearable="false"
        :auto-apply="true"
        :format="'yyyy-MM-dd'"
        :preview-format="'yyyy-MM-dd'"
        :month-change-on-scroll="false"
        :enable-time-picker="false"
      />
      <textarea
        v-if="baseType === 'HierarchyNode'"
        v-model="inputHierarchyNode"
        class="user-preference__textarea"
        type="text"
        placeholder="No value"
        :rows="10"
      />
      <div
        class="helper"
      >
        {{ preference.description }}
      </div>
      <div
        v-if="baseType === 'HierarchyNode'"
        class="helper"
      >
        An example of a HierarchyNode value:<br>
        '&lt;hierarchyNode hierarchyId="CON-CTY-STO" id="468" type="STO"/ &gt;'
      </div>
    </modal>
    <modal
      :enabled="promptEnabled"
      :title="promptTitle || 'Something went wrong'"
      :info="promptInfo || 'It looks like something went wrong. Please try again later.'"
      secondary-button-text="Okay"
      :styles="{ secondaryType: 'primary' }"
      @secondaryClicked="promptEnabled = false"
    />
    <modal
      :enabled="confirmEnabled"
      :title="'Please confirm'"
      :info="'The preference will not be edited.'"
      :primary-button-text="'Confirm'"
      :secondary-button-text="'Cancel'"
      @secondaryClicked="confirmEnabled = false"
      @primaryClicked="confirmEnabled = false; onCancelClicked()"
    />
  </div>
</template>

<script>
import {
  ref,
  computed,
  watch,
  onMounted,
  onUnmounted,
} from 'vue';

import Modal from '@/components/shared/modal.vue';
import OptionDropdown from '@/components/shared/option-dropdown.vue';

import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

import { useUtilsStore } from '@/store/utils';
import { useUserManagementStore } from '@/store/user-management';
import { storeToRefs } from 'pinia';

export default {
  components: {
    Modal,
    OptionDropdown,
    Datepicker,
  },

  props: {
    trigger: { // Increment to open modal (if keeping it mounted).
      type: Number,
      default: 0,
    },
    preference: {
      type: Object,
      required: true,
    },
  },

  emits: ['userPreferenceEdited'],

  async setup(props, { emit }) {
    const {
      user,
    } = storeToRefs(useUserManagementStore());

    const {
      updateUser,
    } = useUserManagementStore();

    /*
    const getAttribute = (xml, attribute) => {
      const re = new RegExp(`${attribute}="([^"]+)"`);
      const match = (xml || '').match(re);
      return match ? match[1] : '';
    };

    const getContent = (xml, tag) => {
      const re = new RegExp(`<${tag}>([^<]+)<`);
      const match = (xml || '').match(re);
      return match ? match[1] : '';
    };
    */

    const { toastRef } = useUtilsStore();
    const sheetEnabled = ref(false); // sheet modal
    const promptEnabled = ref(false); // prompt modal
    const confirmEnabled = ref(false); // confirm modal
    const promptTitle = ref('');
    const promptInfo = ref('');

    const baseType = computed(() => props.preference.preferenceType?.baseType);

    const preferenceString = computed(() => props.preference.value || '');
    const preferenceBoolean = computed(() => (props.preference.value ? (props.preference.value === '1') : null));
    const preferenceDate = computed(() => props.preference.value || null);
    const preferenceHierarchyNode = computed(() => props.preference.value || '');

    /*
    const nodeHierarchyId = computed(() => getAttribute(preferenceHierarchyNode.value, 'hierarchyId'));
    const nodeId = computed(() => getAttribute(preferenceHierarchyNode.value, 'id'));
    const nodeType = computed(() => getAttribute(preferenceHierarchyNode.value, 'type'));

    const nodeConfig = computed(() => props.preference.preferenceType?.config);
    const configHierarchyId = computed(() => getContent(nodeConfig.value, 'hierarchyId'));
    const configHierarchyLevelId = computed(() => getContent(nodeConfig.value, 'hierarchyLevelId'));
    const configPrivilegeId = computed(() => getContent(nodeConfig.value, 'privilegeId'));
    */

    const inputString = ref(preferenceString.value);
    const inputBoolean = ref(preferenceBoolean.value);
    const inputDate = ref(preferenceDate.value);
    const inputHierarchyNode = ref(preferenceHierarchyNode.value);

    const primaryButtonDisabled = computed(() => inputString.value === preferenceString.value
      && inputBoolean.value === preferenceBoolean.value
      && inputDate.value === preferenceDate.value
      && inputHierarchyNode.value === preferenceHierarchyNode.value); // nothing changed

    const resetForm = async () => {
      inputString.value = preferenceString.value;
      inputBoolean.value = preferenceBoolean.value;
      inputDate.value = preferenceDate.value;
      inputHierarchyNode.value = preferenceHierarchyNode.value;
    };

    const onClearClicked = () => {
      inputString.value = '';
      inputBoolean.value = null;
      inputDate.value = null;
      inputHierarchyNode.value = '';
    };

    const onBooleanSelected = (selectedBoolean) => {
      inputBoolean.value = selectedBoolean;
    };

    const onSaveClicked = async () => {
      const payload = {
        globalUserId: user.value.globalUserId,
        preferences: [{
          productCode: props.preference.productCode,
          preferenceCode: props.preference.preferenceCode,
          ...baseType.value === 'String' && {
            value: inputString.value || null,
            // eslint-disable-next-line no-nested-ternary
            operation: !inputString.value ? 'DELETE' : (props.preference.update ? 'UPDATE' : 'CREATE'),
          },
          ...baseType.value === 'Boolean' && {
            // eslint-disable-next-line no-nested-ternary
            value: inputBoolean.value === null ? null : (inputBoolean.value === true ? '1' : '0'),
            // eslint-disable-next-line no-nested-ternary
            operation: inputBoolean.value === null ? 'DELETE' : (props.preference.update ? 'UPDATE' : 'CREATE'),
          },
          ...baseType.value === 'Date' && {
            value: inputDate.value ? new Date(inputDate.value).toLocaleDateString('sv-SE') : null,
            // eslint-disable-next-line no-nested-ternary
            operation: !inputDate.value ? 'DELETE' : (props.preference.update ? 'UPDATE' : 'CREATE'),
          },
          ...baseType.value === 'HierarchyNode' && {
            value: inputHierarchyNode.value || null,
            // eslint-disable-next-line no-nested-ternary
            operation: !inputHierarchyNode.value ? 'DELETE' : (props.preference.update ? 'UPDATE' : 'CREATE'),
          },
        }],
        action: 'COMMIT',
      };
      const status = await updateUser(payload);
      if (status.success) {
        emit('userPreferenceEdited');
        // hide modal
        sheetEnabled.value = false;
        // show toast
        toastRef.value.toastAnimation({
          message: `Changes to ${(props.preference.name)} was saved`,
        });
      } else {
        promptTitle.value = '';
        promptInfo.value = status.message;
        promptEnabled.value = true;
      }
    };

    const onCancelClicked = () => {
      sheetEnabled.value = false;
    };

    const onCloseModal = () => {
      if (!primaryButtonDisabled.value) {
        confirmEnabled.value = true;
      } else {
        sheetEnabled.value = false;
      }
    };

    watch(() => props.trigger, async (showSheet) => {
      await resetForm();
      sheetEnabled.value = showSheet > 0; // Increment trigger to open modal again.
    });

    onMounted(async () => {
      await resetForm();
      sheetEnabled.value = props.trigger > 0;
    });

    onUnmounted(async () => {
      await resetForm();
    });

    return {
      baseType,
      inputString,
      inputBoolean,
      onBooleanSelected,
      inputDate,
      inputHierarchyNode,
      sheetEnabled,
      promptEnabled,
      promptTitle,
      promptInfo,
      confirmEnabled,
      primaryButtonDisabled,
      onClearClicked,
      onSaveClicked,
      onCancelClicked,
      onCloseModal,
    };
  },
};
</script>

<style scoped>
.user-preference__textarea,
.option-dropdown,
.dp__main,
.helper {
  margin-bottom: 1rem;
}

.user-preference__textarea-label {
  position: relative;
  display: inline-block;
  width: 100%;
}

.user-preference__textarea-clear {
  position: absolute;
  right: 0;
  bottom: 0;
  font-size: var(--font-size-25);
  color: var(--colour-text-lightest);
  cursor: pointer;
}

.user-preference__textarea-clear:hover {
  color: var(--colour-text-default);
}

.user-preference__textarea {
  display: block;
  width: 100%;
  color: var(--colour-text-dark);
  resize: vertical;
  background-color: var(--colour-bg-default);
}

.helper {
  margin-top: -0.75rem;
  font-size: var(--font-size-25);
  color: var(--colour-text-lightest);
}

.label--disabled {
  color: var(--colour-disabled-ui);
}
</style>
