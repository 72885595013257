<template>
  <div>
    <div
      class="users__title_info"
    >
      <h1
        class="users_title"
      >
        {{ product.name }}
      </h1>
      <p>
        Here you can search for users to see their roles, privileges and preferences.
        User details will be connected to the selected product.
      </p>
    </div>
    <search
      v-model="searchWord"
      type="rounded"
      class="user-search__search"
    />
    <div
      class="user-search__pills"
    >
      <div
        v-for="(pill, index) in pillFilters"
        :key="toHash(pill)"
      >
        <pill-dropdown
          :items="pill.filters"
          :selected-item="pill.selectedFilter"
          @itemSelected="onPillItemSelected($event, index)"
        />
      </div>
    </div>
    <div
      :class="['user-search__table-wrapper',
               !shownUsers?.length && 'user-search__no-scroll'
      ]"
    >
      <user-table
        :headers="headers"
        :rows="hasEnoughInput ? shownUsers : []"
        :enable-row-click="true"
        @rowClicked="onUserClicked"
      >
        <template
          #column-status="{
            row,
          }"
        >
          <span
            v-if="['DEACTIVATED', 'DELETED', 'SUSPENDED', 'ARCHIVED'].includes(row.status)"
            class="table__data_inactive"
          >
            Inactive
          </span>
          <span
            v-else-if="!['ACTIVE'].includes(row.status)"
          >
            {{ row.status }}
          </span>
          <span
            v-else
          />
        </template>
      </user-table>
    </div>
    <div
      v-if="!hasEnoughInput"
      class="user-search__info-text"
    >
      Please search to list users.
    </div>
    <div
      v-else-if="isSearching"
      class="user-search__info-text"
    >
      <Loading
        text="Searching..."
      >
        <LoadingBall />
      </Loading>
    </div>
    <div
      v-else-if="!shownUsers?.length"
      class="user-search__info-text"
    >
      No result found for "{{ searchQueries.searchCriteria }}". Please try again.
    </div>
    <pagination
      v-else
      :has-more="users.length > noOfShownUsers"
      :shown-items="noOfShownUsers < users.length ? noOfShownUsers : users.length"
      :total="users.length"
      class="user-search__pagination"
      @showMoreClicked="noOfShownUsers += increment;"
    />
    <modal
      :enabled="isErrorModalEnabled"
      :secondary-button-text="'Okay'"
      :styles="{ secondaryType: 'primary' }"
      title="Something went wrong"
      info="It looks like something went wrong. Please try again later."
      @secondaryClicked="isErrorModalEnabled=false"
    />
  </div>
</template>

<script>
import { ref, watch, computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { debounce } from 'lodash';

import PillDropdown from '@/components/shared/pill-dropdown.vue';
import Pagination from '@/components/shared/pagination.vue';
import Search from '@/components/shared/search.vue';
import UserTable from '@/components/shared/table.vue';
import Modal from '@/components/shared/modal.vue';

import Loading, { LoadingBall } from '@ingka/loading-vue';

import { useUtilsStore } from '@/store/utils';
import { useActorStore } from '@/store/actor';

import { useUserManagementStore } from '@/store/user-management';
import { storeToRefs } from 'pinia';

export default {
  components: {
    PillDropdown,
    Search,
    UserTable,
    Pagination,
    Loading,
    LoadingBall,
    Modal,
  },

  setup() {
    const {
      fetchUsers,
    } = useUserManagementStore();

    const {
      product,
    } = storeToRefs(useActorStore());

    const {
      users,
      searchQueries,
    } = storeToRefs(useUserManagementStore());

    const router = useRouter();
    const route = useRoute();

    const { toHash } = useUtilsStore();

    const headers = {
      mainHeaders: [
        { id: 'name', displayName: 'Name' },
        { id: 'status' },
        { id: 'userId', displayName: 'User ID' },
        { id: 'legacyUserId', displayName: 'Network ID' },
        { id: 'title', displayName: 'Title' },
      ],
    };

    const searchWord = ref('');
    const hasEnoughInput = ref(false);
    const isSearching = ref(0);
    const isErrorModalEnabled = ref(false);
    const increment = 10;
    const noOfShownUsers = ref(increment);
    const shownUsers = computed(() => users.value?.slice(0, noOfShownUsers.value).map((user) => ({
      name: user.name,
      status: user.status,
      userId: user.userId,
      legacyUserId: user.legacyUserId,
      title: user.title,
    })));

    const pillFilters = ref([
      {
        selectedFilter: { id: 'all', displayName: 'Name & User ID' },
        filters: [
          { id: 'all', displayName: 'Name & User ID' },
          { id: 'searchNameOnly', displayName: 'Name' },
          { id: 'searchIdOnly', displayName: 'User ID' },
        ],
      },
      {
        selectedFilter: { id: 'all', displayName: 'Contains word' },
        filters: [
          { id: 'all', displayName: 'Contains word' },
          { id: 'useExactMatch', displayName: 'Exact match' },
        ],
      },
      {
        selectedFilter: { id: 'activeUsersOnly', displayName: 'Active users' },
        filters: [
          { id: 'activeUsersOnly', displayName: 'Active users' },
          { id: 'all', displayName: 'Inactive and active users' },
        ],
      },
    ]);

    const searchForUsers = async () => {
      if (searchWord.value.length >= 3) {
        const payload = {
          searchCriteria: searchWord.value,
          searchIdOnly: pillFilters.value[0].selectedFilter.id === pillFilters.value[0].filters[2].id,
          searchNameOnly: pillFilters.value[0].selectedFilter.id === pillFilters.value[0].filters[1].id,
          activeUsersOnly: pillFilters.value[2].selectedFilter.id === pillFilters.value[2].filters[0].id,
          useExactMatch: pillFilters.value[1].selectedFilter.id === pillFilters.value[1].filters[1].id,
        };

        hasEnoughInput.value = true; // enough to search and show result
        isSearching.value += 1; // integer for handling multiple slow asynchronous searches

        await fetchUsers(payload); // waiting...

        noOfShownUsers.value = increment; // resetting result size
        isSearching.value -= 1; // this search is done
      } else {
        hasEnoughInput.value = false;
      }
    };

    watch(() => pillFilters.value, () => {
      searchForUsers();
    }, { deep: true });

    watch(() => searchWord.value, debounce(() => {
      searchForUsers();
    }, 500, {
      leading: false,
      trailing: true,
    }));

    const onPillItemSelected = (filter, index) => {
      pillFilters.value[index].selectedFilter = filter;
    };

    const onUserClicked = async (theUser) => {
      router.push({
        query: {
          ...route.query,
          user: theUser.userId,
        },
      });
    };

    return {
      product,
      searchWord,
      onPillItemSelected,
      pillFilters,
      shownUsers,
      hasEnoughInput,
      isSearching,
      increment,
      noOfShownUsers,
      users,
      searchQueries,
      toHash,
      onUserClicked,
      isErrorModalEnabled,
      headers,
    };
  },
};
</script>

<style scoped>
.users__title_info {
  max-width: 31.875rem;
  padding-top: 2.25rem;
  padding-bottom: 1.375rem;
}

.users_title {
  padding-bottom: 0.5rem;
}

.user-search__pills {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2.25rem;
}

.user-search__pills > * {
  padding-right: 0.875rem;
  padding-bottom: 0.5rem;
}

.user-search__table-wrapper {
  width: 100%;
  overflow-x: auto;
  overflow-y: auto;
}

.user-search__no-scroll {
  overflow: hidden;
}

.user-search__search {
  margin-bottom: 1.25rem;
}

.user-search__info-text {
  margin-top: 4rem;
  color: var(--colour-text-lightest);
  text-align: center;
}

.user-search__pagination {
  margin-top: 1.25rem;
}

.table__data_inactive {
  padding: 0.25rem 0.4375rem;
  color: var(--colour-text-inverse);
  background-color: var(--colour-semantic-negative);
  border-radius: 0.25rem;
}

@media (min-width: 46.375rem) {
  .user-search__pills {
    display: flex;
  }

  .user-search__pills > div {
    padding-right: 0.875rem;
  }
}
</style>
