import { request } from '@/services/api-utils';

/**
 * @param {Object} param0 - The object containing the query properties.
 * @returns
 */
export const getHasPreferenceValue = async ({
  productCode,
  preferenceCode,
  hierarchyCode,
  hierarchyNodeCode,
  hierarchyNodeType,
},
) => request(process.env.VUE_APP_API_SYSTEM_EVALUATION, 'get', '/haspreferencevalue'
+ `?productCode=${productCode}`
+ `&preferenceCode=${preferenceCode}`
+ `&hierarchyCode=${hierarchyCode}`
+ `&hierarchyNodeCode=${hierarchyNodeCode}`
+ `&hierarchyNodeType=${hierarchyNodeType}`);

/**
 * @param {Object} param0 - The object containing the query properties.
 * @returns
 */
export const getPreferenceValues = async ({
  productCode,
  preferenceCode,
  hierarchyCode,
  hierarchyNodeCode,
  hierarchyNodeType,
},
) => request(process.env.VUE_APP_API_SYSTEM_EVALUATION, 'get', '/preferencevalues'
+ `?productCode=${productCode}`
+ `&preferenceCode=${preferenceCode}`
+ `&hierarchyCode=${hierarchyCode}`
+ `&hierarchyNodeCode=${hierarchyNodeCode}`
+ `&hierarchyNodeType=${hierarchyNodeType}`);
