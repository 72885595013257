<template>
  <div class="grid grid-view-layout">
    <page-header
      @productsLoaded="headerLoaded=true"
    />
    <div
      v-if="!product"
    >
      <info-page />
    </div>
    <div
      v-else-if="headerLoaded && product"
      class="users-view__search"
    >
      <user-search
        v-show="version === 'search'"
      />
      <user
        v-show="version === 'userInfo'"
      />
    </div>
    <div
      v-else-if="headerLoaded"
    >
      <error-page
        title="Access denied"
        text="You don't have permission to access this page. Please contact support for help."
        number="403"
      />
    </div>
  </div>
</template>

<script>
import { ref, watch, nextTick } from 'vue';
import { useRoute } from 'vue-router';

import PageHeader from '@/components/domain/page-header.vue';
import UserSearch from '@/components/domain/users/user-search.vue';
import User from '@/components/domain/users/user.vue';
import InfoPage from '@/components/shared/info-page.vue';
import ErrorPage from '@/components/shared/error-page.vue';

import { useActorStore } from '@/store/actor';

import { storeToRefs } from 'pinia';

export default {
  components: {
    PageHeader,
    UserSearch,
    ErrorPage,
    InfoPage,
    User,
  },

  setup() {
    const route = useRoute();

    const version = ref('search');
    const headerLoaded = ref(false);
    const scrollRestore = ref(0);

    const {
      product,
    } = storeToRefs(useActorStore());

    watch(() => route.query.user, (userId) => {
      if (userId) {
        scrollRestore.value = window.top.scrollY;
        version.value = 'userInfo';
      } else {
        version.value = 'search';
        nextTick(() => window.scroll(0, scrollRestore.value));
      }
    }, { immediate: true });

    return {
      version,
      product,
      headerLoaded,
    };
  },
};
</script>

<style scoped>
  .users-view__search {
    max-height: calc(100vh - 10rem);
    padding-right: 1rem;
    overflow-y: auto;
  }

  @media (min-width: 64rem) {
    .users-view__search {
      max-height: calc(100vh - 6rem);
    }
  }
</style>
