<template>
  <div
    ref="dropref"
  >
    <dropdown
      v-model:visible="visible"
      v-arrow-navigable
      class="pill__dropdown"
      type="pill"
      @keyup.tab="hide"
    >
      <template #activator>
        <dropdown-button
          v-model:active="visible"
          type="pill"
          tabindex="0"
          data-arrow-navigable-trigger
          data-arrow-navigable-item
        >
          <dropdown-label
            v-model:active="visible"
            type="pill"
          >
            <span
              v-if="selectedItem.displayName"
              class="pill__name pill__name--selected"
            >
              {{ selectedItem.displayName }}
            </span>
            <span
              v-else
              class="pill__name"
            >
              {{ items[0].displayName }}
            </span>
          </dropdown-label>
        </dropdown-button>
      </template>

      <list>
        <list-item
          v-for="item in items"
          :key="toHash(item)"
          data-arrow-navigable-item
          tabindex="0"
          @click="selectItem(item)"
          @keyup.enter.prevent="selectItem(item)"
        >
          <span
            :class="['', {
              'pill__name--selected': item.displayName === selectedItem.displayName
            }]"
          >
            {{ item.displayName }}
          </span>
        </list-item>
      </list>
    </dropdown>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useOnOutsidePress } from 'vue-composable';

import Dropdown from '@/components/shared/dropdown.vue';
import DropdownButton from '@/components/shared/dropdown-button.vue';
import DropdownLabel from '@/components/shared/dropdown-label.vue';
import List from '@/components/shared/list.vue';
import ListItem from '@/components/shared/list-item.vue';

import { useVisibility } from '@/composables/visibility';
import { useUtilsStore } from '@/store/utils';

export default {
  components: {
    Dropdown,
    DropdownButton,
    DropdownLabel,
    List,
    ListItem,
  },

  props: {
    items: {
      type: Array,
      required: true,
    },
    selectedItem: {
      type: Object,
      required: false,
      default: () => {},
    },
  },

  emits: ['itemSelected'],

  setup(props, { emit }) {
    const { visible, show, hide } = useVisibility();
    const { toHash } = useUtilsStore();

    const dropref = ref(null);
    useOnOutsidePress(dropref, () => hide());

    const selectItem = (item) => {
      emit('itemSelected', item);
      hide();
    };

    return {
      dropref,
      selectItem,
      visible,
      toHash,
      show,
      hide,
    };
  },
};
</script>

<style scoped>
.pill__dropdown {
  width: 100%;
}

.pill__name {
  width: 100%;
  font-size: 0.75rem;
}

.pill__name--selected {
  font-weight: bold;
}
</style>
