<template>
  <div>
    <modal
      :enabled="sheetEnabled"
      type="sheets"
      title="Add role to user"
      primary-button-text="Add"
      secondary-button-text="Cancel"
      :primary-button-disabled="primaryButtonDisabled"
      :secondary-button-disabled="false"
      @primaryClicked="onSaveClicked"
      @secondaryClicked="onCancelClicked"
      @closeModal="onCloseModal"
    >
      <template
        v-if="!product || product.code === 'All products'"
      >
        <label>Product *</label>
        <option-dropdown
          :items="activeProducts"
          :selected-item="selectedProduct"
          name-attribute="name"
          @itemSelected="onProductSelected"
        />
      </template>
      <label
        :class="{ 'label--disabled': !selectedProduct || !activeRoles }"
      >Role *</label>
      <option-dropdown
        :disabled="!selectedProduct || !activeRoles"
        :items="activeRoles"
        :selected-item="selectedRole"
        name-attribute="roleCode"
        @itemSelected="onRoleSelected"
      />
      <div
        v-if="selectedProduct && !canManageProduct"
        class="helper"
      >
        Based on roles you can delegate
      </div>
      <label
        :class="{ 'label--disabled': !selectedRole || !activeHierarchies }"
      >Hierarchy *</label>
      <option-dropdown
        :disabled="!selectedRole || !activeHierarchies"
        :items="activeHierarchies"
        :selected-item="selectedHierarchy"
        name-attribute="name"
        @itemSelected="onHierarchySelected"
      />
      <label
        :class="{ 'label--disabled': !selectedHierarchy || !sortedHierarchyNodesDict }"
      >Node *</label>
      <hierarchy-nodes-search
        :disabled="!selectedHierarchy || !sortedHierarchyNodesDict"
        :hierarchy-nodes="searchedForHierarchyNodes || []"
        :selected-hierarchy-node="hierarchyNode"
        @hierarchyNodeSelected="onHierarchyNodeSelected"
      />
      <tree
        v-arrow-navigable
        :disabled="!hierarchyNode || !sortedHierarchyNodesDict"
      >
        <tree-node
          v-if="hierarchyNode && sortedHierarchyNodesDict"
          :node="sortedHierarchyNodesDict"
          :expanded="true"
          :selected-node="hierarchyNode"
          @nodeSelected="onHierarchyNodeSelected"
        />
      </tree>
      <checkbox
        v-model="canDelegate"
        :enabled="!!hierarchyNode"
        label="Can delegate"
      />
    </modal>
    <modal
      :enabled="promptEnabled"
      :title="promptTitle || 'Something went wrong'"
      :info="promptInfo || 'It looks like something went wrong. Please try again later.'"
      secondary-button-text="Okay"
      :styles="{ secondaryType: 'primary' }"
      @secondaryClicked="promptEnabled = false"
    />
    <modal
      :enabled="confirmEnabled"
      :title="'Please confirm'"
      :info="'The role will not be added.'"
      :primary-button-text="'Confirm'"
      :secondary-button-text="'Cancel'"
      @secondaryClicked="confirmEnabled = false"
      @primaryClicked="confirmEnabled = false; onCancelClicked()"
    />
  </div>
</template>

<script>
import {
  ref,
  computed,
  watch,
  onMounted,
  onUnmounted,
} from 'vue';

import Modal from '@/components/shared/modal.vue';
import OptionDropdown from '@/components/shared/option-dropdown.vue';
import HierarchyNodesSearch from '@/components/domain/hierarchy-nodes-search.vue';
import Tree from '@/components/shared/tree.vue';
import TreeNode from '@/components/shared/tree-node.vue';
import Checkbox from '@/components/shared/checkbox.vue';

import { useHierarchiesStore } from '@/store/hierarchy-management';
import { useUtilsStore } from '@/store/utils';
import { useUserManagementStore } from '@/store/user-management';
import { useUserEvaluationStore } from '@/store/user-evaluation';
import { useActorStore } from '@/store/actor';

import { union, uniqBy } from 'lodash';

import { storeToRefs } from 'pinia';

export default {
  components: {
    Modal,
    OptionDropdown,
    Tree,
    TreeNode,
    HierarchyNodesSearch,
    Checkbox,
  },

  props: {
    trigger: { // Increment to open modal (if keeping it mounted).
      type: Number,
      default: 0,
    },
    actorCanDelegateRoles: { // Roles I have as "Can delegate".
      type: Array,
      default: () => [],
    },
    actorManageProducts: { // The products I have with the ManageAnyUserRoles privilege.
      type: Array,
      default: () => [],
    },
  },

  emits: ['userRoleAdded'],

  async setup(props, { emit }) {
    const { compareByProperty } = useUtilsStore();

    const {
      updateUser,
      getPrivileges,
      getRoles,
    } = useUserManagementStore();

    const {
      user,
      privileges,
      roles,
    } = storeToRefs(useUserManagementStore());

    const {
      product,
      actor,
    } = storeToRefs(useActorStore());

    const {
      hierarchyNode,
      sortedHierarchyNodesDict,
    } = storeToRefs(useHierarchiesStore());

    const {
      products,
    } = storeToRefs(useUserEvaluationStore());

    const {
      fetchHierarchyNodes,
      searchedForHierarchyNodes,
      storeHierarchy,
      storeHierarchyNode,
      resetHierarchy,
      resetHierarchyNode,
      resetHierarchyNodes,
      resetSearchedForHierarchyNodes,
    } = useHierarchiesStore();

    const { toastRef } = useUtilsStore();
    const sheetEnabled = ref(false); // sheet modal
    const promptEnabled = ref(false); // prompt modal
    const confirmEnabled = ref(false); // confirm modal
    const promptTitle = ref('');
    const promptInfo = ref('');

    const selectedProduct = ref(null);
    const activeProductPrivileges = ref(null);
    const selectedRole = ref(null);
    const activeProductRoles = ref(null);
    const selectedHierarchy = ref(null);
    const canDelegate = ref('0');

    const primaryButtonDisabled = computed(() => !hierarchyNode.value); // can't save/add yet

    // Only list products I can delegate roles for
    // and products I can manage all roles for (privilege ManageAnyUserRoles).
    const activeProducts = computed(() => (products.value?.length
      ? [...products.value].sort(compareByProperty('name')).filter((p) => {
        if (props.actorCanDelegateRoles.some((r) => r.productCode === p.code)) { // can delegate
          return true;
        }
        if (props.actorManageProducts.some((p2) => p2.code === p.code)) { // can manange
          return true;
        }
        return false;
      })
      : []));

    // If I can manage all roles for selected product (privilege ManageAnyUserRoles).
    const canManageProduct = computed(() => props.actorManageProducts
      .some((p) => selectedProduct.value?.code === p.code));

    // Product privileges if a specific product is selected in this modal,
    // else use privileges from the general product selected in the header dropdown.
    const activePrivileges = computed(() => activeProductPrivileges.value || privileges.value || []);

    // Product roles if a specific product is selected in this modal,
    // else use roles from the general product selected in the header menu.
    const activeRoles = computed(() => {
      const list = (activeProductRoles.value || roles.value || [])
        .filter((r) => {
          // If can delegate role or manage all roles for the product.
          if (props.actorCanDelegateRoles
            .some((r2) => r2.productCode === selectedProduct.value?.code && r2.roleCode === r.roleCode)) { // can delegate
            return true;
          }
          if (canManageProduct.value) { // can manage
            return true;
          }
          return false;
        });
      // Remove roles that have the same roleCode
      return uniqBy(list, 'roleCode');
    });

    // List of hierarchies (via the privileges of the selected role), if can manage the product.
    const canManageHierarchies = computed(() => (canManageProduct.value
      ? activePrivileges.value
        .filter((p) => selectedRole.value?.privilegeCodes?.includes(p.privilegeCode))
        .map((p) => p.hierarchyCode)
        .filter((h, pos, self) => self.indexOf(h) === pos) // removing duplicates
        .sort()
        .map((h) => ({
          code: h,
          name: h,
        }))
      : null));

    // Can-delegate-hierarchies (including node).
    const canDelegateHierarchies = computed(() => props.actorCanDelegateRoles
      .filter((r) => r.productCode === selectedProduct.value?.code
        && r.roleCode === selectedRole.value?.roleCode)
      .map((r) => ({
        code: r.hierarchyCode,
        hierarchyNodeCode: r.hierarchyNodeCode,
        hierarchyNodeType: r.hierarchyNodeType,
        name: `${r.hierarchyCode}${r.hierarchyNodeCode !== 'ROOT' ? ` (${r.hierarchyNodeCode})` : ''}`,
      })));

    const activeHierarchies = computed(() => {
      let list = [];
      console.log('canManageHierarchies', canManageHierarchies.value);
      console.log('canDelegateHierarchies', canDelegateHierarchies.value);
      if (canManageHierarchies.value) {
        list = union(list, canManageHierarchies.value);
      }
      if (canDelegateHierarchies.value) {
        list = union(list, canDelegateHierarchies.value);
      }
      return uniqBy(list, 'name');
    });

    const resetForm = async () => {
      if (product.value && product.value.code !== 'All products') {
        // A product is selected in the general header product dropdown.
        selectedProduct.value = product.value;
      } else {
        // Let user select a specific product for this modal.
        selectedProduct.value = null;
      }
      selectedRole.value = null;
      selectedHierarchy.value = null;
      await resetHierarchy();
      await resetHierarchyNode();
      await resetHierarchyNodes();
      await resetSearchedForHierarchyNodes();
      canDelegate.value = '0';
    };

    const onSaveClicked = async () => {
      const payload = {
        globalUserId: user.value.globalUserId,
        roles: [{
          productCode: selectedProduct.value.code,
          roleCode: selectedRole.value.roleCode,
          hierarchyCode: selectedHierarchy.value.code,
          hierarchyNodeCode: hierarchyNode.value.code,
          hierarchyNodeType: hierarchyNode.value.type,
          canDelegate: canDelegate.value === '1',
          operation: 'CREATE',
        }],
        action: 'COMMIT',
      };
      const status = await updateUser(payload);
      if (status.success) {
        emit('userRoleAdded');

        // hide modal
        sheetEnabled.value = false;

        // show toast
        toastRef.value.toastAnimation({
          message: `${(selectedRole.value?.roleCode)} was added to roles`,
        });
      } else {
        promptTitle.value = '';
        promptInfo.value = status.message;
        promptEnabled.value = true;
      }
    };

    const onCancelClicked = () => {
      sheetEnabled.value = false;
    };

    const onCloseModal = () => {
      if (!primaryButtonDisabled.value) {
        confirmEnabled.value = true;
      } else {
        sheetEnabled.value = false;
      }
    };

    const onProductSelected = (newProduct) => {
      selectedProduct.value = newProduct || null;
    };

    const onRoleSelected = (newRole) => {
      selectedRole.value = newRole || null;
    };

    const onHierarchySelected = async (newHierarchy) => {
      console.log('Hierarchy selected', newHierarchy);
      selectedHierarchy.value = newHierarchy || null;
      if (newHierarchy?.code) {
        await storeHierarchy(newHierarchy.code);
      } else {
        await resetHierarchy();
        await resetHierarchyNode();
        await resetHierarchyNodes();
        await resetSearchedForHierarchyNodes();
      }
    };

    const onHierarchyNodeSelected = async (selectedHierarchyNode) => {
      console.log('Node selected', selectedHierarchyNode);
      if (selectedHierarchyNode) {
        await storeHierarchyNode(selectedHierarchyNode);
      } else {
        await resetHierarchyNode();
      }
    };

    watch(() => props.trigger, async (showSheet) => {
      await resetForm();
      sheetEnabled.value = showSheet > 0; // Increment trigger to open modal again.
    });

    watch(() => product.value, (newProduct) => {
      if (newProduct && newProduct.code !== 'All products') {
        // Product changed in hamburger menu.
        selectedProduct.value = newProduct;
      } else {
        roles.value = undefined;
      }
    });

    const loadRolesForProduct = async (prod) => {
      try {
        const { roles: r } = await getRoles({ productCode: prod.code });
        activeProductRoles.value = r.map((role) => ({
          roleCode: role.name,
          ...role,
        }));
      } catch (err) {
        promptTitle.value = '';
        promptInfo.value = 'Unable to get roles for the selected product.';
        promptEnabled.value = true;
      }
      try {
        const { privileges: p } = await getPrivileges({ productCode: prod.code });
        activeProductPrivileges.value = p;
      } catch (err) {
        promptTitle.value = '';
        promptInfo.value = 'Unable to get privileges for the selected product.';
        promptEnabled.value = true;
      }
    };

    watch(() => selectedProduct.value, async (newProduct) => {
      selectedRole.value = null;
      if (newProduct) {
        // Get roles and privileges if a specific product was selected in this modal.
        activeProductRoles.value = [];
        activeProductPrivileges.value = [];
        loadRolesForProduct(newProduct);
      } else {
        activeProductRoles.value = null;
        activeProductPrivileges.value = null;
      }
    });

    watch(() => selectedRole.value, async () => {
      selectedHierarchy.value = null;
      await resetHierarchy();
      await resetHierarchyNode();
      await resetHierarchyNodes();
      await resetSearchedForHierarchyNodes();
    });

    watch(() => activeHierarchies.value, (newHierarchies) => {
      if (newHierarchies && newHierarchies.length === 1) {
        onHierarchySelected(newHierarchies[0]);
      }
    });

    watch(() => selectedHierarchy.value, async (newHierarchy) => {
      // Can-delegate-roles are limited to a hierarchy node (or nodes below).
      if (newHierarchy && newHierarchy.code) {
        const payload = {
          hierarchyCode: newHierarchy.code,
          hierarchyNodeCode: newHierarchy.hierarchyNodeCode || '',
          hierarchyNodeType: newHierarchy.hierarchyNodeType || '',
          ancestors: false,
        };
        const success = await fetchHierarchyNodes(payload);
        if (!success) {
          promptTitle.value = '';
          promptInfo.value = 'Unable to get nodes for the selected hierarchy.';
          promptEnabled.value = true;
        }
      }
    });

    watch(() => sortedHierarchyNodesDict.value, async (nodesDict) => {
      if (nodesDict) {
        await onHierarchyNodeSelected(nodesDict); // init
      }
    });

    onMounted(async () => {
      await resetForm();
      if (product.value.code !== 'All products') {
        loadRolesForProduct(product.value);
      } else {
        roles.value = undefined;
      }
      sheetEnabled.value = props.trigger > 0;
    });

    onUnmounted(async () => {
      await resetForm();
    });

    return {
      actor,
      roles,
      product,
      selectedProduct,
      activeProductRoles,
      activeProducts,
      selectedRole,
      activeRoles,
      selectedHierarchy,
      activeHierarchies,
      canDelegateHierarchies, // Todo remove
      hierarchyNode,
      sortedHierarchyNodesDict,
      searchedForHierarchyNodes,
      sheetEnabled,
      promptEnabled,
      promptTitle,
      promptInfo,
      confirmEnabled,
      canDelegate,
      primaryButtonDisabled,
      canManageProduct,
      onProductSelected,
      onRoleSelected,
      onHierarchySelected,
      onHierarchyNodeSelected,
      onSaveClicked,
      onCancelClicked,
      onCloseModal,
    };
  },
};
</script>

<style scoped>
.option-dropdown,
.tree,
.checkbox,
.helper {
  margin-bottom: 1rem;
}

.helper {
  margin-top: -0.75rem;
  font-size: var(--font-size-25);
  color: var(--colour-text-lightest);
}

.label--disabled {
  color: var(--colour-disabled-ui);
}

.hierarchy-node-search {
  margin-bottom: 0.5rem;
}

:deep(.tree__nodes) {
  height: 17rem;
  overflow-x: hidden;
  overflow-y: scroll;
}
</style>
