<template>
  <div
    ref="dropref"
    class="option-dropdown"
  >
    <div
      class="option-dropdown__label"
    >
      {{ label }}
    </div>
    <dropdown
      v-model:visible="visible"
      v-arrow-navigable
      :disabled="disabled || empty"
      @keydown.tab="hide"
    >
      <template #activator>
        <dropdown-button
          v-model:active="visible"
          :disabled="disabled || empty"
          tabindex="0"
          data-arrow-navigable-trigger
          data-arrow-navigable-item
        >
          <dropdown-label
            v-model:active="visible"
            class="option-dropdown__dropdown-label"
            :disabled="disabled || empty"
          >
            <span
              v-if="typeof selectedItem !== 'undefined' && selectedItem !== null"
              class="option-dropdown__name option-dropdown__name--selected"
            >
              {{ getName(selectedItem) }}
            </span>
            <span
              v-else
              class="option-dropdown__name"
            >
              {{ empty ? emptyText : defaultText }}
            </span>
          </dropdown-label>
        </dropdown-button>
      </template>

      <list>
        <list-item
          v-for="item in items"
          :key="toHash(item)"
          data-arrow-navigable-item
          tabindex="0"
          @click="selectItem(item)"
          @keydown.enter.prevent="selectItem(item)"
        >
          <span
            :class="['', {
              'option-dropdown__name--selected': getName(item) === getName(selectedItem)
            }]"
          >
            {{ getName(item) }}
          </span>
        </list-item>
      </list>
    </dropdown>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { useOnOutsidePress } from 'vue-composable';

import Dropdown from '@/components/shared/dropdown.vue';
import DropdownButton from '@/components/shared/dropdown-button.vue';
import DropdownLabel from '@/components/shared/dropdown-label.vue';
import List from '@/components/shared/list.vue';
import ListItem from '@/components/shared/list-item.vue';

import { useVisibility } from '@/composables/visibility';

import { useUtilsStore } from '@/store/utils';

export default {
  components: {
    Dropdown,
    DropdownButton,
    DropdownLabel,
    List,
    ListItem,
  },

  props: {
    items: {
      type: Array,
      required: true,
    },
    selectedItem: {
      type: [Object, String, Boolean],
      required: false,
      default: () => undefined,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    defaultText: {
      type: String,
      default: 'Choose an option',
    },
    emptyText: {
      type: String,
      default: 'No options available',
    },
    nameAttribute: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
  },

  emits: ['itemSelected'],

  setup(props, { emit }) {
    const { visible, show, hide } = useVisibility();
    const { toHash } = useUtilsStore();

    const empty = computed(() => (!props.items || !props.items.length));

    const dropref = ref(null);
    useOnOutsidePress(dropref, () => hide());

    const getName = (item) => (props.nameAttribute ? (item || {})[props.nameAttribute] : `${item}`);

    const selectItem = (item) => {
      emit('itemSelected', item);
      hide();
    };

    return {
      dropref,
      getName,
      selectItem,
      visible,
      empty,
      toHash,
      show,
      hide,
    };
  },
};
</script>

<style scoped>
.option-dropdown__label {
  padding-bottom: 0.125rem;
}

.option-dropdown__name {
  width: 100%;
}

.option-dropdown__name--selected {
  font-weight: var(--font-weight-bold);
}

.option-dropdown__dropdown-label {
  height: 3rem;
}
</style>
