<template>
  <div>
    <modal
      :enabled="sheetEnabled"
      type="sheets"
      title="Add privilege to user"
      primary-button-text="Add"
      secondary-button-text="Cancel"
      :primary-button-disabled="primaryButtonDisabled"
      :secondary-button-disabled="false"
      @primaryClicked="onSaveClicked"
      @secondaryClicked="onCancelClicked"
      @closeModal="onCloseModal"
    >
      <template
        v-if="product.code === 'All products'"
      >
        <label>Product *</label>
        <option-dropdown
          :items="activeProducts"
          :selected-item="selectedProduct"
          name-attribute="name"
          @itemSelected="onProductSelected"
        />
      </template>
      <label
        :class="{ 'label--disabled': !selectedProduct || !activePrivileges }"
      >Privilege *</label>
      <option-dropdown
        :disabled="!selectedProduct || !activePrivileges"
        :items="activePrivileges"
        :selected-item="selectedPrivilege"
        name-attribute="privilegeCode"
        @itemSelected="onPrivilegeSelected"
      />
      <div
        v-if="selectedProduct && !canManageProduct"
        class="helper"
      >
        Based on privileges you can delegate
      </div>
      <label
        :class="{ 'label--disabled': !selectedPrivilege || !activeHierarchies }"
      >Hierarchy *</label>
      <option-dropdown
        :disabled="!selectedPrivilege || !activeHierarchies"
        :items="activeHierarchies"
        :selected-item="selectedHierarchy"
        name-attribute="name"
        @itemSelected="onHierarchySelected"
      />
      <label
        :class="{ 'label--disabled': !selectedHierarchy || !sortedHierarchyNodesDict }"
      >Node *</label>
      <hierarchy-nodes-search
        :disabled="!selectedHierarchy || !sortedHierarchyNodesDict"
        :hierarchy-nodes="searchedForHierarchyNodes || []"
        :selected-hierarchy-node="hierarchyNode"
        @hierarchyNodeSelected="onHierarchyNodeSelected"
      />
      <tree
        v-arrow-navigable
        :disabled="!hierarchyNode || !sortedHierarchyNodesDict"
      >
        <tree-node
          v-if="hierarchyNode && sortedHierarchyNodesDict"
          :node="sortedHierarchyNodesDict"
          :expanded="true"
          :selected-node="hierarchyNode"
          @nodeSelected="onHierarchyNodeSelected"
        />
      </tree>
      <checkbox
        v-model="canDelegate"
        :enabled="!!hierarchyNode"
        label="Can delegate"
      />
    </modal>
    <modal
      :enabled="promptEnabled"
      :title="promptTitle || 'Something went wrong'"
      :info="promptInfo || 'It looks like something went wrong. Please try again later.'"
      secondary-button-text="Okay"
      :styles="{ secondaryType: 'primary' }"
      @secondaryClicked="promptEnabled = false"
    />
    <modal
      :enabled="confirmEnabled"
      :title="'Please confirm'"
      :info="'The privilege will not be added.'"
      :primary-button-text="'Confirm'"
      :secondary-button-text="'Cancel'"
      @secondaryClicked="confirmEnabled = false"
      @primaryClicked="confirmEnabled = false; onCancelClicked()"
    />
  </div>
</template>

<script>
import {
  ref,
  computed,
  watch,
  onMounted,
  onUnmounted,
} from 'vue';

import Modal from '@/components/shared/modal.vue';
import OptionDropdown from '@/components/shared/option-dropdown.vue';
import HierarchyNodesSearch from '@/components/domain/hierarchy-nodes-search.vue';
import Tree from '@/components/shared/tree.vue';
import TreeNode from '@/components/shared/tree-node.vue';
import Checkbox from '@/components/shared/checkbox.vue';

import { useHierarchiesStore } from '@/store/hierarchy-management';
import { useUtilsStore } from '@/store/utils';
import { useUserManagementStore } from '@/store/user-management';
import { useUserEvaluationStore } from '@/store/user-evaluation';
import { useActorStore } from '@/store/actor';

import { uniqBy } from 'lodash';

import { storeToRefs } from 'pinia';

export default {
  components: {
    Modal,
    OptionDropdown,
    Tree,
    TreeNode,
    HierarchyNodesSearch,
    Checkbox,
  },

  props: {
    trigger: { // Increment to open modal (if keeping it mounted).
      type: Number,
      default: 0,
    },
    privilegeProducts: { // Products with CanAssignPrivilegeToUser.
      type: Array,
      default: () => [],
    },
    actorCanDelegatePrivileges: { // Privileges I have as "Can delegate".
      type: Array,
      default: () => [],
    },
    actorManageProducts: { // The products I have with the ManageAnyUserPrivileges privilege.
      type: Array,
      default: () => [],
    },
  },

  emits: ['userPrivilegeAdded'],

  async setup(props, { emit }) {
    const { compareByPropertyIgnoreCase } = useUtilsStore();

    const {
      updateUser,
      getPrivileges,
    } = useUserManagementStore();

    const {
      user,
      privileges,
    } = storeToRefs(useUserManagementStore());

    const {
      product,
    } = storeToRefs(useActorStore());

    const {
      hierarchyNode,
    } = storeToRefs(useHierarchiesStore());

    const {
      products,
    } = storeToRefs(useUserEvaluationStore());

    const {
      fetchHierarchyNodes,
      storeHierarchy,
      storeHierarchyNode,
      resetHierarchy,
      resetHierarchyNode,
      resetHierarchyNodes,
      resetSearchedForHierarchyNodes,
    } = useHierarchiesStore();

    const {
      searchedForHierarchyNodes,
      sortedHierarchyNodesDict,
    } = storeToRefs(useHierarchiesStore());

    const { toastRef } = useUtilsStore();
    const sheetEnabled = ref(false); // sheet modal
    const promptEnabled = ref(false); // prompt modal
    const confirmEnabled = ref(false); // confirm modal
    const promptTitle = ref('');
    const promptInfo = ref('');

    const selectedProduct = ref(null);
    const selectedPrivilege = ref(null);
    const activeProductPrivileges = ref(null);
    const selectedHierarchy = ref(null);
    const canDelegate = ref('0');

    const primaryButtonDisabled = computed(() => !hierarchyNode.value); // can't save/add yet

    // Only list products I can delegate privileges for
    // and products I can manage all privileges for (privilege ManageAnyUserPrivileges).
    const activeProducts = computed(() => (products.value?.length
      ? [...products.value].sort(compareByPropertyIgnoreCase('name')).filter((p) => {
        if (!props.privilegeProducts.some((privProd) => privProd === p.code)) { // not a privilege product
          return false;
        }
        if (props.actorCanDelegatePrivileges.some((priv) => priv.productCode === p.code)) { // can delegate
          return true;
        }
        if (props.actorManageProducts.some((prod) => prod.code === p.code)) { // can manange
          return true;
        }
        return false;
      })
      : []));

    // If I can manage all privileges for selected product (privilege ManageAnyUserPrivileges).
    const canManageProduct = computed(() => props.actorManageProducts
      .some((p) => selectedProduct.value?.code === p.code));

    // Product privileges if a specific product is selected in this modal,
    // else use privileges from the general product selected in the header dropdown.
    const activePrivileges = computed(() => {
      const activeList = (activeProductPrivileges.value || privileges.value || [])
        .filter((p) => {
          // If can delegate privilege or manage all privileges for the product.
          if (props.actorCanDelegatePrivileges
            .some((priv) => priv.productCode === selectedProduct.value?.code
            && priv.privilegeCode === p.privilegeCode)) { // can delegate
            return true;
          }
          if (canManageProduct.value) { // can manange
            return true;
          }
          return false;
        });

      // Remove privileges that have the same privilegeCode
      const unique = uniqBy(activeList, 'privilegeCode');
      unique.sort(compareByPropertyIgnoreCase('privilegeCode'));
      return unique;
    });

    // Hierarchy, if can manage the product.
    const canManageHierarchies = computed(() => (canManageProduct.value
      ? [{
        code: selectedPrivilege.value?.hierarchyCode,
        name: selectedPrivilege.value?.hierarchyCode,
      }]
      : null));

    // Can-delegate-hierarchies (including node).
    const canDelegateHierarchies = computed(() => props.actorCanDelegatePrivileges
      .filter((priv) => priv.productCode === selectedProduct.value?.code
        && priv.privilegeCode === selectedPrivilege.value?.privilegeCode)
      .map((priv) => ({
        code: priv.hierarchyCode,
        name: `${priv.hierarchyCode}${priv.hierarchyNodeCode !== 'ROOT' ? ` (${priv.hierarchyNodeCode})` : ''}`,
        hierarchyNodeCode: priv.hierarchyNodeCode,
        hierarchyNodeType: priv.hierarchyNodeType,
      })));

    const activeHierarchies = computed(() => canManageHierarchies.value || canDelegateHierarchies.value || []);

    const resetForm = async () => {
      if (product.value && product.value.code !== 'All products') {
        // A product is selected in the general header product dropdown.
        selectedProduct.value = product.value;
      } else {
        // Let user select a specific product for this modal.
        selectedProduct.value = null;
      }
      selectedPrivilege.value = null;
      selectedHierarchy.value = null;
      await resetHierarchy();
      await resetHierarchyNode();
      await resetHierarchyNodes();
      await resetSearchedForHierarchyNodes();
      canDelegate.value = '0';
    };

    const onSaveClicked = async () => {
      const payload = {
        globalUserId: user.value.globalUserId,
        privileges: [{
          productCode: selectedProduct.value.code,
          privilegeCode: selectedPrivilege.value.privilegeCode,
          hierarchyCode: selectedHierarchy.value.code,
          hierarchyNodeCode: hierarchyNode.value.code,
          hierarchyNodeType: hierarchyNode.value.type,
          canDelegate: canDelegate.value === '1',
          operation: 'CREATE',
        }],
        action: 'COMMIT',
      };
      const status = await updateUser(payload);
      if (status.success) {
        emit('userPrivilegeAdded');

        // hide modal
        sheetEnabled.value = false;

        // show toast
        toastRef.value.toastAnimation({
          message: `${(selectedPrivilege.value?.privilegeCode)} was added to privileges`,
        });
      } else {
        promptTitle.value = '';
        promptInfo.value = status.message;
        promptEnabled.value = true;
      }
    };

    const onCancelClicked = () => {
      sheetEnabled.value = false;
    };

    const onCloseModal = () => {
      if (!primaryButtonDisabled.value) {
        confirmEnabled.value = true;
      } else {
        sheetEnabled.value = false;
      }
    };

    const onProductSelected = (newProduct) => {
      selectedProduct.value = newProduct || null;
    };

    const onPrivilegeSelected = (newPrivilege) => {
      selectedPrivilege.value = newPrivilege || null;
    };

    const onHierarchySelected = async (newHierarchy) => {
      selectedHierarchy.value = newHierarchy || null;
      if (newHierarchy?.code) {
        await storeHierarchy(newHierarchy.code);
      } else {
        await resetHierarchy();
        await resetHierarchyNode();
        await resetHierarchyNodes();
        await resetSearchedForHierarchyNodes();
      }
    };

    const onHierarchyNodeSelected = async (selectedHierarchyNode) => {
      if (selectedHierarchyNode) {
        await storeHierarchyNode(selectedHierarchyNode);
      } else {
        await resetHierarchyNode();
      }
    };

    watch(() => props.trigger, async (showSheet) => {
      await resetForm();
      sheetEnabled.value = showSheet > 0; // Increment trigger to open modal again.
    });

    watch(() => product.value, (newProduct) => {
      selectedProduct.value = null;
      if (newProduct && newProduct.code !== 'All products') {
        // Product changed in general header dropdown.
        selectedProduct.value = newProduct;
      }
    });

    const loadPrivilegesForProduct = async (prod) => {
      try {
        const { privileges: p } = await getPrivileges({ productCode: prod.code });
        activeProductPrivileges.value = p;
      } catch (err) {
        promptTitle.value = '';
        promptInfo.value = 'Unable to get privileges for the selected product.';
        promptEnabled.value = true;
      }
    };

    watch(() => selectedProduct.value, async (newProduct, oldProduct) => {
      selectedPrivilege.value = null;
      if (newProduct && (!product.value || (newProduct.code !== oldProduct?.code))) {
        // Get privileges if a specific product was selected in this modal.
        activeProductPrivileges.value = [];
        loadPrivilegesForProduct(newProduct);
      } else {
        activeProductPrivileges.value = null;
      }
    });

    watch(() => selectedPrivilege.value, async () => {
      selectedHierarchy.value = null;
      await resetHierarchy();
      await resetHierarchyNode();
      await resetHierarchyNodes();
      await resetSearchedForHierarchyNodes();
    });

    watch(() => activeHierarchies.value, (newHierarchies) => {
      if (newHierarchies && newHierarchies.length === 1) {
        onHierarchySelected(newHierarchies[0]);
      }
    });

    watch(() => selectedHierarchy.value, async (newHierarchy) => {
      // Can-delegate-privileges are limited to a hierarchy node (or nodes below).
      if (newHierarchy && newHierarchy.code) {
        const payload = {
          hierarchyCode: newHierarchy.code,
          hierarchyNodeCode: newHierarchy.hierarchyNodeCode || '',
          hierarchyNodeType: newHierarchy.hierarchyNodeType || '',
          ancestors: false,
        };
        const success = await fetchHierarchyNodes(payload);
        if (!success) {
          promptTitle.value = '';
          promptInfo.value = 'Unable to get nodes for the selected hierarchy.';
          promptEnabled.value = true;
        }
      }
    });

    watch(() => sortedHierarchyNodesDict.value, async (nodesDict) => {
      if (nodesDict) {
        await onHierarchyNodeSelected(nodesDict); // init
      }
    });

    onMounted(async () => {
      await resetForm();
      sheetEnabled.value = props.trigger > 0;
      if (product.value.name !== 'All products') {
        loadPrivilegesForProduct(product.value);
      }
    });

    onUnmounted(async () => {
      await resetForm();
    });

    return {
      product,
      selectedProduct,
      activeProducts,
      selectedPrivilege,
      activePrivileges,
      selectedHierarchy,
      activeHierarchies,
      hierarchyNode,
      sortedHierarchyNodesDict,
      searchedForHierarchyNodes,
      sheetEnabled,
      promptEnabled,
      promptTitle,
      promptInfo,
      confirmEnabled,
      canDelegate,
      primaryButtonDisabled,
      canManageProduct,
      onProductSelected,
      onPrivilegeSelected,
      onHierarchySelected,
      onHierarchyNodeSelected,
      onSaveClicked,
      onCancelClicked,
      onCloseModal,
    };
  },
};
</script>

<style scoped>
.option-dropdown,
.tree,
.checkbox,
.helper {
  margin-bottom: 1rem;
}

.helper {
  margin-top: -0.75rem;
  font-size: var(--font-size-25);
  color: var(--colour-text-lightest);
}

.label--disabled {
  color: var(--colour-disabled-ui);
}

.hierarchy-node-search {
  margin-bottom: 0.5rem;
}

:deep(.tree__nodes) {
  height: 17rem;
  overflow-x: hidden;
  overflow-y: scroll;
}
</style>
