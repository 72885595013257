<template>
  <div
    class="table__wrapper"
  >
    <div
      v-if="headers?.mainHeaders"
      class="table__content"
    >
      <table
        class="table"
      >
        <thead
          ref="contentTableHeader"
          class="table__header"
        >
          <th
            v-for="header in headers.mainHeaders"
            :key="header.id"
            :class="[isSortableHeader(header) && 'table__name_NONE',
                     isSortableHeader(header) && selectedHeader === header.id && 'table__name_' + order]"
            @click="header.function && header.function() || onHeaderClick(header)"
          >
            <span
              :class="['table__name', header.spanClass]"
            >
              {{ header.displayName }}
            </span>
          </th>
          <th
            v-for="(header, a) in headers.stickyHeaders"
            :ref="el => { if (el) stickyHeaderElements[a] = el }"
            :key="header.id"
            class="table__header_width table__sticky-column"
            :class="[isSortableHeader(header) && 'table__name_NONE',
                     isSortableHeader(header) && selectedHeader === header.id && 'table__name_' + order]"
            @click="header.function && header.function() || onHeaderClick(header)"
          >
            <span
              :class="['table__name', header.spanClass]"
            >
              {{ header.displayName }}
            </span>
          </th>
        </thead>
        <tbody>
          <tr
            v-for="(row, rowIndex) in sortedRows"
            :key="toHash(row)"
            :class="['table__row',
                     enableRowClick && 'table__row_clickable']"
            @click="onRowClicked(row)"
          >
            <td
              v-for="column in headerIds"
              :key="toHash(row[column] || column)"
              class="table__data"
            >
              <div
                class="table__data_content"
              >
                <slot
                  :name="`column-${column}`"
                  :row="row"
                >
                  {{ row[column] }}
                </slot>
              </div>
            </td>
            <td
              v-for="(column, b) in headers.stickyHeaders"
              :ref="el => { if (el) stickyDataElements[(rowIndex * headers.stickyHeaders.length) + b] = el }"
              :key="toHash(row[column] || column)"
              class="table__data table__sticky-column"
            >
              <slot
                :name="`column-${column.id}`"
                :row="row"
              >
                {{ row[column] }}
              </slot>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {
  ref, watch, toRefs, computed, onUpdated, onMounted,
} from 'vue';
import { cloneDeep } from 'lodash';

import { useUtilsStore } from '@/store/utils';

export default {
  props: {
    /**
     * @property {string} id
     * @property {string} displayName
     * @property {string} spanClass span tag CSS class
     * @property {boolean} sorting
     * @property {function} function callback function when clicked
     * @property {string} style header style
     */
    headers: {
      type: Object,
      default: () => {},
    },
    rows: {
      type: Array,
      default: () => [],
    },
    enableRowClick: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['rowClicked'],

  setup(props, { emit }) {
    const { rows } = toRefs(props);
    const sortedRows = ref([]);
    const order = ref('NONE');
    const { toHash } = useUtilsStore();

    // To just show row (cell) data matching the header attributes.
    const headerIds = computed(() => props.headers.mainHeaders.map((h) => h.id));

    watch(rows, () => {
      sortedRows.value = cloneDeep(rows.value);
    }, { deep: true, immediate: true });

    const isSortableHeader = (header) => sortedRows.value.length
      && header.displayName
      && header.sorting !== false;

    const sortByHeader = (header) => {
      const sortOrder = (order.value === 'DESC' ? -1 : 1);
      if (order.value === 'NONE') {
        sortedRows.value = cloneDeep(rows.value);
        return;
      }
      sortedRows.value.sort((a, b) => {
        if ((a[header]?.toString().toLowerCase() || '') < (b[header]?.toString().toLowerCase() || '')) {
          return -1 * sortOrder;
        }
        if ((a[header]?.toString().toLowerCase() || '') > (b[header]?.toString().toLowerCase() || '')) {
          return 1 * sortOrder;
        }
        return 0;
      });
    };

    const selectedHeader = ref('');

    const onRowClicked = (row) => {
      emit('rowClicked', row);
    };

    const onHeaderClick = (header) => {
      if (isSortableHeader(header)) {
        if (selectedHeader.value !== header.id) {
          selectedHeader.value = header.id;
          order.value = 'NONE';
        }

        if (order.value === 'NONE') {
          order.value = 'ASC';
        } else if (order.value === 'ASC') {
          order.value = 'DESC';
        } else {
          order.value = 'NONE';
        }
        sortByHeader(header.id);
      }
    };

    const stickyHeaderElements = ref([]);
    const stickyDataElements = ref([]);

    const recalculateStickyness = () => {
      stickyHeaderElements.value.reverse();
      stickyDataElements.value.reverse();
      const rights = [0];

      for (let headerIndex = 0; headerIndex < stickyHeaderElements.value.length; headerIndex += 1) {
        stickyHeaderElements.value[headerIndex].style.right = `${rights[headerIndex]}px`;
        rights.push(stickyHeaderElements.value[headerIndex].clientWidth - 1);
      }

      const boxShadow = '-5px 10px 10px 0px var(--colour-neutral-grey-100)';
      if (stickyHeaderElements.value.length) {
        stickyHeaderElements.value[stickyHeaderElements.value.length - 1].style.boxShadow = boxShadow;
      }

      // The mod ( % ) allows us to know which column it is.
      for (let dataIndex = 0; dataIndex < stickyDataElements.value.length; dataIndex += 1) {
        stickyDataElements.value[dataIndex].style.right = `${rights[dataIndex % stickyHeaderElements.value.length]}px`;
        if (dataIndex % stickyHeaderElements.value.length === stickyHeaderElements.value.length - 1) {
          stickyDataElements.value[dataIndex].style.boxShadow = boxShadow;
        }
      }
    };

    onUpdated(recalculateStickyness);
    onMounted(recalculateStickyness);

    return {
      order,
      toHash,
      headerIds,
      sortedRows,
      isSortableHeader,
      onHeaderClick,
      selectedHeader,
      onRowClicked,
      stickyHeaderElements,
      stickyDataElements,
    };
  },
};
</script>

<style scoped>
.table__wrapper {
  display: flex;
}

.table {
  width: 100%;
  border-spacing: 0;
}

.table__sticky-column {
  position: sticky;
  width: 1%;
  background-color: var(--colour-bg-default);
}

th {
  vertical-align: bottom;
}

th:last-child {
  padding-right: 0.25rem;
}

td {
  vertical-align: top;
}

.table__name {
  font-weight: normal;
  color: var(--colour-text-default);
}

.table__header {
  overflow: hidden;
  white-space: nowrap;
  border-bottom: 0.0625rem solid var(--colour-border-light);
}

.table__content {
  display: block;
  width: 100%;
  overflow-x: auto;
}

.table__header_width {
  justify-content: center;
  padding-right: 0;
  padding-left: 1rem;
}

.table__header_delete {
  display: flex;
  justify-content: flex-end;
  padding-right: 0;
  font-weight: var(--font-weight-bold);
  color: var(--colour-semantic-negative);
  cursor: pointer;
}

.table__header_delete--disabled {
  display: flex;
  justify-content: flex-end;
  padding-right: 0;
  font-weight: var(--font-weight-bold);
  color: var(--colour-disabled-ui);
}

.table__header_delete--disabled::before {
  width: 1rem;
  height: 1rem;
  margin-right: 0.375rem;
  content: '';
  background-image: url("../../assets/svg/trash-can-disabled.svg");
  background-repeat: no-repeat;
  background-size: contain;
}

.table__header_delete::before {
  width: 1rem;
  height: 1rem;
  margin-right: 0.375rem;
  cursor: pointer;
  content: '';
  background-image: url("../../assets/svg/trash-can-red.svg");
  background-repeat: no-repeat;
  background-size: contain;
}

.table__name_NONE {
  height: 1.125rem;
  cursor: pointer;
}

.table__name_NONE::after {
  height: 1rem;
  padding-right: 1.75rem;
  margin-left: 0.3rem;
  vertical-align: text-bottom;
  cursor: pointer;
  content: '';
  background: transparent;
  background-image: url("../../assets/svg/chevron-up-chevron-down-small-light.svg");
  background-repeat: no-repeat;
  background-size: 1rem;
}

.table__name_ASC::after {
  height: 1rem;
  padding-right: 1.75rem;
  margin-left: 0.3rem;
  vertical-align: text-bottom;
  cursor: pointer;
  content: '';
  background: transparent;
  background-image: url("../../assets/svg/chevron-up-small-light.svg");
  background-repeat: no-repeat;
  background-size: 1rem;
}

.table__name_DESC::after {
  height: 1rem;
  padding-right: 1.75rem;
  margin-left: 0.3rem;
  vertical-align: text-bottom;
  cursor: pointer;
  content: '';
  background: transparent;
  background-image: url("../../assets/svg/chevron-down-small-light.svg");
  background-repeat: no-repeat;
  background-size: 1rem;
}

.table__row_clickable {
  cursor: pointer;
}

.table__data {
  min-width: 4.75rem;
  max-width: 12rem;
  min-height: 3rem;
  padding: 1.25rem 0.25rem 1.25rem 0;
  color: var(--colour-text-dark);
  border-bottom: 0.0625rem solid var(--colour-border-light);
}

.table__data_content {
  min-height: 2.5rem;
  padding-top: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.table__data_sticky-column {
  min-width: 4.75rem;
  max-width: 12rem;
  padding: 0;
}

@media (min-width: 46.375rem) {
  .table__data {
    min-width: 4.75rem;
  }
}
</style>
