<template>
  <div>
    <span
      class="user__back"
    >
      <Button
        text="Back"
        small
        :ssr-icon="arrowLeft"
        type="plain"
        @click="onBackClicked"
      />
    </span>
    <template
      v-if="user"
    >
      <span
        class="user__name_inactive"
      >
        <h1>{{ user?.name }}</h1>
        <p
          v-if="user?.status !== 'ACTIVE'"
          class="user__inactive"
        >
          Inactive
        </p>
      </span>
      <span
        class="user__info text text--body-s"
      >
        <span
          v-if="user?.userId"
        >
          {{ user?.userId }}
        </span>
        <span
          v-if="user?.legacyUserId"
        >
          {{ user?.legacyUserId }}
        </span>
        <span
          v-if="user?.title"
        >
          {{ user?.title }}
        </span>
      </span>
      <tabs
        :tabs="tabs"
        :selected-tab="activeTab"
        :has-line="false"
        class="user__tabs"
        @tabClicked="onChangeTab"
      />
      <span
        class="user__tab-title-button"
      >
        <h2
          class="user__tab-title text text--heading-m"
        >
          {{ activeTab.name }} for {{ product.name }}
        </h2>
        <Button
          v-if="activeTab.name === 'Roles'"
          :disabled="!canAddRole"
          text="Add role"
          small
          :ssr-icon="plus"
          type="primary"
          @click="onAddRoleClicked"
        />
        <Button
          v-if="activeTab.name === 'Privileges'"
          :disabled="!canAddPrivilege"
          text="Add privilege"
          small
          :ssr-icon="plus"
          type="primary"
          @click="onAddPrivilegeClicked"
        />
      </span>
      <div
        class="user__content"
      >
        <div
          v-if="activeTab.name === 'Privileges'"
          class="user__privilege-table"
        >
          <user-table
            :headers="privilegeTableHeaders"
            :rows="userPrivilegeRows"
          >
            <template
              #column-privilege="{
                row,
              }"
            >
              <template
                v-if="row.productPrivilege"
              >
                {{ row.productPrivilege.name }}<br>
                <span class="helper">{{ row.productPrivilege.description }}</span>
              </template>
            </template>
            <template
              #column-node="{
                row,
              }"
            >
              {{ row.userPrivilege.hierarchyNodeCode }} ({{ row.userPrivilege.hierarchyNodeType }})<br>
              <span class="helper">{{ row.userPrivilege.hierarchyCode }}</span>
            </template>
            <template #column-canDelegate="slotProps">
              <div
                class="user__checkbox-wrapper"
              >
                <div class="checkbox user__checkbox">
                  <input
                    :disabled="!canDelegatePrivilege(slotProps.row)"
                    :checked="slotProps.row.canDelegate"
                    type="checkbox"
                    @click.prevent="onCanDelegatePrivClicked($event, slotProps.row)"
                  >
                  <div
                    class="checkbox__symbol"
                  />
                </div>
              </div>
            </template>
            <template #column-delete="{ row }">
              <Button
                :disabled="!canManageProduct(row.userPrivilege.productCode)"
                text="Delete"
                small
                :ssr-icon="trashCan"
                class="user__delete-button"
                type="tertiary"
                @click="onDeletePrivClicked(row)"
              />
            </template>
          </user-table>
          <div
            v-if="!userPrivilegeRows?.length"
            class="user__no-privileges"
          >
            This user has no privileges added for the selected product.
          </div>
        </div>
        <div
          v-if="activeTab.name === 'Preferences'"
          class="user__preference-table"
        >
          <user-table
            :headers="{'mainHeaders': [
              ...(product?.code === 'All products' ? [{ id: 'product', displayName: 'Product' }] : []),
              { id: 'preference', displayName: 'Preference' },
              { id: 'value', displayName: 'Value' },
            ], 'stickyHeaders': [{ id: 'edit' }] }"
            :rows="userPreferenceRows"
          >
            <template
              #column-preference="{
                row,
              }"
            >
              {{ row.preferenceObject.name }}<br>
              <span class="helper">{{ row.preferenceObject.description }}</span>
            </template>
            <template
              #column-value="{
                row,
              }"
            >
              <template
                v-if="isEditablePreference(row.preferenceObject)"
              >
                <span
                  v-if="row.value === undefined"
                  class="helper"
                >
                  No value
                </span>
                <span
                  v-else-if="row.preferenceObject.preferenceType?.baseType === 'Boolean'"
                >
                  {{ row.value === '1' ? true : false }}
                </span>
                <span
                  v-else-if="row.preferenceObject.preferenceType?.baseType === 'HierarchyNode'"
                >
                  {{ getNodeAttribute(row.value, 'id') }} ({{ getNodeAttribute(row.value, 'type') }})<br>
                  <span class="helper">{{ getNodeAttribute(row.value, 'hierarchyId') }}</span>
                </span>
              </template>
              <template
                v-else
              >
                <span
                  class="helper"
                >
                  Value type not supported,<br>please contact support
                </span>
              </template>
            </template>
            <template
              #column-edit="{
                row,
              }"
            >
              <Button
                v-if="isEditablePreference(row.preferenceObject)"
                :disabled="!canManageProduct(row.preferenceObject.productCode)"
                text="Edit"
                small
                :ssr-icon="pencil"
                type="tertiary"
                @click="onEditPreferenceClicked(row.preferenceObject)"
              />
            </template>
          </user-table>
          <div
            v-if="!userPreferenceRows?.length"
            class="user__no-preferences"
          >
            This product has no preferences.
          </div>
        </div>
        <div
          v-if="activeTab.name === 'Roles'"
          class="user__role-table"
        >
          <user-table
            :headers="rolesTableHeaders"
            :rows="userRoleRows || []"
            :enable-row-click="false"
          >
            <template #column-canDelegate="{ row }">
              <div
                class="user__checkbox-wrapper"
              >
                <div class="checkbox user__checkbox">
                  <input
                    :disabled="!canDelegateRole(row.userRole)"
                    :checked="row.canDelegate"
                    type="checkbox"
                    @click.prevent="onCanDelegateRoleClicked($event, row.userRole)"
                  >
                  <div
                    class="checkbox__symbol"
                  />
                </div>
              </div>
            </template>
            <template #column-node="slotProps">
              {{ slotProps.row.userRole.hierarchyNodeCode }} ({{ slotProps.row.userRole.hierarchyNodeType }})
              <br>
              <span
                class="user__node-hierarchy"
              >
                {{ slotProps.row.userRole.hierarchyCode }}
              </span>
            </template>
            <template #column-delete="{ row }">
              <Button
                :disabled="!canDelegateRole(row.userRole)"
                text="Delete"
                small
                class="user__delete-button"
                :ssr-icon="trashCan"
                type="tertiary"
                @click="onDeleteRoleClicked(row)"
              />
            </template>
          </user-table>
          <div
            v-if="!userRoleRows?.length"
            class="user__no-roles"
          >
            This user has no roles added for the selected product.
          </div>
        </div>
      </div>
    </template>
    <UserRole
      v-if="showRoleSheet > 0"
      :trigger="showRoleSheet"
      :actor-can-delegate-roles="actorCanDelegateRoles"
      :actor-manage-products="actorManageProducts"
      @userRoleAdded="onUserRoleAdded"
    />
    <UserPrivilege
      v-if="showPrivilegeSheet > 0"
      :trigger="showPrivilegeSheet"
      :privilege-products="privilegeProducts"
      :actor-can-delegate-privileges="actorCanDelegatePrivileges"
      :actor-manage-products="actorManageProducts"
      @userPrivilegeAdded="onUserPrivilegeAdded"
    />
    <UserPreference
      v-if="showPreferenceSheet > 0"
      :trigger="showPreferenceSheet"
      :preference="preferenceToEdit"
      @userPreferenceEdited="onUserPreferenceEdited"
    />
    <modal
      :enabled="promptEnabled"
      :title="promptTitle || 'Something went wrong'"
      :info="promptInfo || 'It looks like something went wrong. Please try again later.'"
      secondary-button-text="Okay"
      :styles="{ secondaryType: 'primary' }"
      @secondaryClicked="promptEnabled = false"
    />
    <modal
      :title="deletePromptInfo.title"
      :enabled="deletePromptInfo.enabled"
      primary-button-text="Delete"
      secondary-button-text="Cancel"
      :styles="{type: 'danger'}"
      @secondaryClicked="deletePromptInfo.enabled = false"
      @primaryClicked="onDeletePromptConfirmClicked(deletePromptInfo)"
    >
      This will delete the {{ deletePromptInfo.text2 }}
      <b> {{ deletePromptInfo.text }} </b> for <b> {{ user?.name }} </b>.
      This cannot be undone.
    </modal>
  </div>
</template>

<script>

import {
  ref,
  reactive,
  computed,
  watch,
  onMounted,
} from 'vue';
import { useRouter, useRoute } from 'vue-router';

import Tabs from '@/components/shared/tabs.vue';
import UserTable from '@/components/shared/table.vue';
import UserRole from '@/components/domain/users/add/user-role.vue';
import UserPrivilege from '@/components/domain/users/add/user-privilege.vue';
import UserPreference from '@/components/domain/users/edit/user-preference.vue';
import Modal from '@/components/shared/modal.vue';

import { usePreferenceValues } from '@/composables/system-evaluation/preference-values';

import { useHierarchiesStore } from '@/store/hierarchy-management';
import { useUserManagementStore } from '@/store/user-management';
import { useUserEvaluationStore } from '@/store/user-evaluation';
import { useActorStore } from '@/store/actor';
import { useUtilsStore } from '@/store/utils';

import Button from '@ingka/button-vue';
import Icons from '@ingka/ssr-icon-vue/icons';

import { storeToRefs } from 'pinia';

import { useAuth0 } from '@auth0/auth0-vue';

export default {
  components: {
    Tabs,
    UserTable,
    UserRole,
    UserPrivilege,
    UserPreference,
    Modal,
    Button,
  },

  setup() {
    const router = useRouter();
    const route = useRoute();

    const auth0 = useAuth0();

    const {
      toHash,
      toastRef,
    } = useUtilsStore();

    const {
      user,
      preferences,
      privileges,
    } = storeToRefs(useUserManagementStore());

    const {
      getUser,
      fetchUser,
      updateUser,
      fetchPreferences,
      resetPreferences,
    } = useUserManagementStore();

    const {
      product,
    } = storeToRefs(useActorStore());

    const {
      getHierarchyNodes,
    } = useHierarchiesStore();

    const {
      getHierarchyNodesForPrivilege,
    } = useUserEvaluationStore();

    const {
      products,
    } = storeToRefs(useUserEvaluationStore());

    const {
      getHasPreferenceValue,
      getPreferenceValues,
    } = usePreferenceValues();

    const privilegeProducts = ref([]); // product with CanAssignPrivilegeToUser

    const tabs = computed(() => {
      const list = [
        { name: 'Roles' },
      ];
      if (privilegeProducts.value?.length) {
        list.push({ name: 'Privileges' });
      }
      if (preferences.value?.length) {
        list.push({ name: 'Preferences' });
      }
      return list;
    });

    const activeTab = ref(tabs.value[0]);
    const showRoleSheet = ref(0); // role modal
    const showPrivilegeSheet = ref(0); // privilege modal
    const showPreferenceSheet = ref(0); // preference modal
    const promptEnabled = ref(false); // prompt modal
    const promptTitle = ref('');
    const promptInfo = ref('');
    const actor = ref();
    const actorCanDelegateRoles = ref([]);
    const actorCanDelegatePrivileges = ref([]);
    const actorManageProducts = ref([]);
    const preferenceToEdit = ref(null);

    const deletePromptInfo = reactive({
      enabled: false,
      text: '',
      text2: '',
      title: 'Delete?',
      data: [],
      type: null,
    });

    // eslint-disable-next-line arrow-body-style
    const userRoleRows = computed(() => (user.value?.roles || []).map((userRole) => {
      // The attributes matching the header ids should have sortable values.
      return {
        product: products.value?.find((prod) => prod.code === userRole.productCode)?.name || userRole.productCode,
        role: userRole.roleCode,
        node: `${userRole.hierarchyNodeCode} ${userRole.hierarchyNodeType} ${userRole.hierarchyCode}`,
        canDelegate: userRole.canDelegate,
        userRole,
      };
    }));

    const deleteAllRoles = () => {
      deletePromptInfo.enabled = true;
      let rolesToDelete;
      if (product.value.code === 'All products') {
        rolesToDelete = userRoleRows.value;
      } else {
        rolesToDelete = userRoleRows.value.filter((row) => row.userRole.productCode === product.value.code);
      }
      deletePromptInfo.text2 = rolesToDelete.length > 1 ? 'roles' : 'role';
      deletePromptInfo.title = rolesToDelete.length > 1 ? 'Delete roles?' : 'Delete role?';
      deletePromptInfo.text = rolesToDelete.map((row) => row.userRole.roleCode).join(', ');
      deletePromptInfo.data = rolesToDelete;
      deletePromptInfo.type = 'role';
    };

    const userPrivilegeRows = computed(() => (user.value?.privileges || [])
      .filter((userPriv) => privilegeProducts.value
        .some((privilegeProduct) => userPriv.productCode === privilegeProduct)) // only show CanAssignPrivilegeToUser products
      .map((userPriv) => {
        const productPrivilege = privileges.value?.find((prodPriv) => prodPriv.productCode === userPriv.productCode
          && prodPriv.privilegeCode === userPriv.privilegeCode
          && prodPriv.hierarchyCode === userPriv.hierarchyCode);
        // The attributes matching the header ids should have sortable values.
        return {
          product: products.value?.find((prod) => prod.code === userPriv.productCode).name || userPriv.productCode,
          privilege: productPrivilege ? `${productPrivilege.name} ${productPrivilege.description}` : userPriv.privilegeCode,
          node: `${userPriv.hierarchyNodeCode} ${userPriv.hierarchyNodeType} ${userPriv.hierarchyCode}`,
          canDelegate: userPriv.canDelegate,
          userPrivilege: userPriv,
          productPrivilege,
        };
      }));

    const deleteAllPrivileges = () => {
      deletePromptInfo.enabled = true;
      let privilegesToDelete;
      if (product.value.code === 'All products') {
        privilegesToDelete = userPrivilegeRows.value;
      } else {
        privilegesToDelete = userPrivilegeRows.value
          .filter((row) => row.userPrivilege.productCode === product.value.code);
      }
      deletePromptInfo.text2 = privilegesToDelete.length > 1 ? 'privileges' : 'privilege';
      deletePromptInfo.title = privilegesToDelete.length > 1 ? 'Delete privileges?' : 'Delete privilege?';
      deletePromptInfo.text = privilegesToDelete.map((row) => row.userPrivilege.privilegeCode).join(', ');
      deletePromptInfo.data = privilegesToDelete;
      deletePromptInfo.type = 'privilege';
    };

    const canManageProduct = (productCode) => (actorManageProducts.value || [])
      .some((prod) => prod.code === productCode);

    const canManageAllProducts = (productCodes) => {
      if (!productCodes) {
        return false;
      }
      return !productCodes.some((productCode) => !canManageProduct(productCode));
    };

    const canDelegateRole = (userRole) => {
      // Is the actor looking at it self?
      if (actor.value.user.globalUserId === user.value.globalUserId) {
        return false;
      }
      // Does the actor have the privilege ManageAnyUserRoles for the product?
      if (canManageProduct(userRole.productCode)) {
        return true;
      }

      return actorCanDelegateRoles.value.some((actorRole) => {
        // Same role?
        if ((actorRole.roleCode === userRole.roleCode)
        && ((actorRole.productCode === userRole.productCode))) {
          // Same node?
          if (actorRole.hierarchyNodeCode === userRole.hierarchyNodeCode
            && actorRole.hierarchyCode === userRole.hierarchyCode
            && actorRole.hierarchyNodeType === userRole.hierarchyNodeType) {
            return true;
          }
          // Children of node?
          return actorRole.children?.hierarchyNodes.some((node) => {
            if (node.hierarchyCode === userRole.hierarchyCode
            && node.code === userRole.hierarchyNodeCode
            && node.type === userRole.hierarchyNodeType) {
              return true;
            }
            return false;
          });
        }
        return false;
      });
    };

    const canDelegateAllRoles = (roles) => {
      if (!roles) {
        return false;
      }
      return !roles.some((role) => !canDelegateRole(role));
    };

    const rolesTableHeaders = computed(() => {
      const mainHeaders = [
        { id: 'role', displayName: 'Role' },
        { id: 'node', displayName: 'Node' },
      ];

      const stickyHeaders = [
        { id: 'canDelegate', displayName: 'Can delegate' },
      ];
      if (userRoleRows.value?.length) {
        stickyHeaders.push({
          id: 'delete',
          displayName: 'Delete all',
          spanClass: canDelegateAllRoles(userRoleRows.value.map((row) => row.userRole))
            ? 'table__header_delete' : 'table__header_delete--disabled',
          sorting: false,
          function: canDelegateAllRoles(userRoleRows.value.map((row) => row.userRole))
            ? deleteAllRoles : null,
        });
      }
      if (product.value?.code === 'All products') {
        mainHeaders.unshift({ id: 'product', displayName: 'Product' });
      }
      return { mainHeaders, stickyHeaders };
    });

    const privilegeTableHeaders = computed(() => {
      const mainHeaders = [
        ...(product.value?.code === 'All products' ? [{ id: 'product', displayName: 'Product' }] : []),
        { id: 'privilege', displayName: 'Privilege' },
        { id: 'node', displayName: 'Node' },
      ];
      const stickyHeaders = [{ id: 'canDelegate', displayName: 'Can delegate' }];
      if (userPrivilegeRows.value?.length) {
        stickyHeaders.push({
          id: 'delete',
          displayName: 'Delete all',
          spanClass: canManageAllProducts(userPrivilegeRows.value.map((row) => row.userPrivilege.productCode))
            ? 'table__header_delete' : 'table__header_delete--disabled',
          sorting: false,
          function: canManageAllProducts(userPrivilegeRows.value.map((row) => row.userPrivilege.productCode))
            ? deleteAllPrivileges : null,
        });
      }
      return { mainHeaders, stickyHeaders };
    });

    const getUserPreference = (productCode, preferenceCode) => user
      .value?.preferences?.find((pref) => pref.productCode === productCode && pref.preferenceCode === preferenceCode);

    const isEditablePreference = (pref) => pref.preferenceType?.type === 'Single'
      && ['String', 'Boolean', 'Date', 'HierarchyNode'].includes(pref.preferenceType?.baseType);

    const getNodeAttribute = (node, attribute) => {
      const re = new RegExp(`${attribute}="([^"]+)"`);
      const match = (node || '').match(re);
      return match ? match[1] : '';
    };

    const userPreferenceRows = computed(() => (preferences.value || [])
      .map((pref) => ({
        product: products.value?.find((prod) => prod.code === pref.productCode).name || pref.productCode,
        preference: `${pref.name} ${pref.description}`,
        value: getUserPreference(pref.productCode, pref.preferenceCode)?.value,
        preferenceObject: pref,
      })));

    const canAddRole = computed(() => {
      if (product.value && product.value.code !== 'All products') {
        return actorCanDelegateRoles.value?.some((r) => r.productCode === product.value.code)
          || actorManageProducts.value?.some((p) => p.code === product.value.code);
      }
      return actorCanDelegateRoles.value?.length || actorManageProducts.value?.length;
    });

    const canAddPrivilege = computed(() => {
      const canDelegatePrivileges = actorCanDelegatePrivileges.value?.filter((priv) => privilegeProducts.value
        .some((privilegeProduct) => priv.productCode === privilegeProduct));
      const manageProducts = actorManageProducts.value?.filter((prod) => privilegeProducts.value
        .some((privilegeProduct) => prod.code === privilegeProduct));
      if (product.value && product.value.code !== 'All products') {
        return canDelegatePrivileges?.some((priv) => priv.productCode === product.value.code)
          || manageProducts?.some((prod) => prod.code === product.value.code);
      }
      return canDelegatePrivileges?.length || manageProducts?.length;
    });

    const loadUser = async (userId) => {
      const id = userId || user.value?.userId;
      if (id) {
        const payload = {
          userId: id,
          productCode: product.value && product.value.code !== 'All products' ? product.value.code : '',
        };
        const success = await fetchUser(payload);
        if (!success) {
          promptTitle.value = '';
          promptInfo.value = 'Unable to get user data.';
          promptEnabled.value = true;
        }
      }
    };

    const canDelegatePrivilege = (row) => {
      const { userPrivilege } = row;

      // Does the actor have the privilege ManageAnyUserPrivileges for the product?
      if (canManageProduct(userPrivilege.productCode)) {
        return true;
      }

      return actorCanDelegatePrivileges.value.some((actorPrivilege) => {
        // Same role?
        if ((actorPrivilege.privilegeCode === userPrivilege.privilegeCode)
        && ((actorPrivilege.productCode === userPrivilege.productCode))) {
          // Same node?
          if (actorPrivilege.hierarchyNodeCode === userPrivilege.hierarchyNodeCode
            && actorPrivilege.hierarchyCode === userPrivilege.hierarchyCode
            && actorPrivilege.hierarchyNodeType === userPrivilege.hierarchyNodeType) {
            return true;
          }
          // Children of node?
          return actorPrivilege.children.hierarchyNodes.some((node) => {
            if (node.hierarchyCode === userPrivilege.hierarchyCode
            && node.code === userPrivilege.hierarchyNodeCode
            && node.type === userPrivilege.hierarchyNodeType) {
              return true;
            }
            return false;
          });
        }
        return false;
      });
    };

    const onAddRoleClicked = () => {
      // Will mount the modal on first increment,
      // then simply re-open the modal on the following increments.
      showRoleSheet.value += 1;
    };

    const onAddPrivilegeClicked = () => {
      showPrivilegeSheet.value += 1; // Mount on first, then re-open on increments.
    };

    const onEditPreferenceClicked = (pref) => {
      if (canManageProduct(pref.productCode)) {
        const userPreference = getUserPreference(pref.productCode, pref.preferenceCode);
        preferenceToEdit.value = {
          ...pref,
          value: userPreference?.value,
          update: !!userPreference,
        };
        showPreferenceSheet.value += 1; // Mount on first, then re-open on increments.
      }
    };

    const onCanDelegateRoleClicked = async (event, userRole) => {
      const payload = {
        globalUserId: user.value.globalUserId,
        roles: [{
          productCode: userRole.productCode,
          roleCode: userRole.roleCode,
          hierarchyCode: userRole.hierarchyCode,
          hierarchyNodeCode: userRole.hierarchyNodeCode,
          hierarchyNodeType: userRole.hierarchyNodeType,
          canDelegate: event.target.checked,
          operation: 'UPDATE',
        }],
        action: 'COMMIT',
      };

      const status = await updateUser(payload);

      if (status.success) {
        // show toast
        toastRef.value.toastAnimation({
          message: `The role ${userRole.roleCode} was updated.`,
        });
        // eslint-disable-next-line no-param-reassign
        event.target.checked = !event.target.checked;
      } else {
        console.log('Error when updating can delegate for role:', status.message);
        promptEnabled.value = true;
      }
    };

    const onCanDelegatePrivClicked = async (event, row) => {
      const payload = {
        globalUserId: user.value.globalUserId,
        privileges: [{
          productCode: row.userPrivilege.productCode,
          privilegeCode: row.userPrivilege.privilegeCode,
          hierarchyCode: row.userPrivilege.hierarchyCode,
          hierarchyNodeCode: row.userPrivilege.hierarchyNodeCode,
          hierarchyNodeType: row.userPrivilege.hierarchyNodeType,
          canDelegate: event.target.checked,
          operation: 'UPDATE',
        }],
        action: 'COMMIT',
      };
      const status = await updateUser(payload);

      if (status.success) {
        // show toast
        toastRef.value.toastAnimation({
          message: `The privilege ${row.userPrivilege.privilegeCode} was updated.`,
        });
        // eslint-disable-next-line no-param-reassign
        event.target.checked = !event.target.checked;
      } else {
        console.log('Error when updating can delegate for privilege:', status.message);
        promptEnabled.value = true;
      }
    };

    const onUserRoleAdded = () => {
      loadUser();
    };

    const onUserPrivilegeAdded = () => {
      loadUser();
    };

    const onUserPreferenceEdited = () => {
      loadUser();
    };

    const onDeleteRoleClicked = (row) => {
      deletePromptInfo.enabled = true;
      deletePromptInfo.title = 'Delete role?';
      deletePromptInfo.data = row;
      deletePromptInfo.type = 'role';
      deletePromptInfo.text = row.userRole.roleCode;
      deletePromptInfo.text2 = 'role';
    };

    const onDeletePrivClicked = (row) => {
      deletePromptInfo.enabled = true;
      deletePromptInfo.title = 'Delete privilege?';
      deletePromptInfo.data = row;
      deletePromptInfo.type = 'privilege';
      deletePromptInfo.text = row.userPrivilege.privilegeCode;
      deletePromptInfo.text2 = 'privilege';
    };

    const onDeletePromptConfirmClicked = async (info) => {
      const { type } = info;
      let payload;

      if (type === 'role') {
        // eslint-disable-next-line arrow-body-style
        const roles = (Array.isArray(info.data) ? info.data : [info.data]).map((row) => {
          return {
            productCode: row.userRole.productCode,
            roleCode: row.userRole.roleCode,
            hierarchyCode: row.userRole.hierarchyCode,
            hierarchyNodeCode: row.userRole.hierarchyNodeCode,
            hierarchyNodeType: row.userRole.hierarchyNodeType,
            canDelegate: row.canDelegate,
            operation: 'DELETE',
          };
        });
        payload = {
          globalUserId: user.value.globalUserId,
          roles,
          action: 'COMMIT',
        };
        const status = await updateUser(payload);
        if (status?.success) {
          if (roles.length > 1) {
            toastRef.value.toastAnimation({
              message: 'The roles were deleted.',
            });
          } else {
            toastRef.value.toastAnimation({
              message: `The role ${roles[0].roleCode} was deleted.`,
            });
          }
        } else {
          promptEnabled.value = true;
        }
      } else if (type === 'privilege') {
        // eslint-disable-next-line arrow-body-style
        const privs = (Array.isArray(info.data) ? info.data : [info.data]).map((row) => {
          return {
            productCode: row.userPrivilege.productCode,
            privilegeCode: row.userPrivilege.privilegeCode,
            hierarchyCode: row.userPrivilege.hierarchyCode,
            hierarchyNodeCode: row.userPrivilege.hierarchyNodeCode,
            hierarchyNodeType: row.userPrivilege.hierarchyNodeType,
            canDelegate: row.canDelegate,
            operation: 'DELETE',
          };
        });
        payload = {
          globalUserId: user.value.globalUserId,
          privileges: privs,
          action: 'COMMIT',
        };
        const status = await updateUser(payload);
        if (status?.success) {
          if (privs.length > 1) {
            toastRef.value.toastAnimation({
              message: 'The privileges were deleted.',
            });
          } else {
            toastRef.value.toastAnimation({
              message: `The privilege ${privs[0].privilegeCode} was deleted.`,
            });
          }
        } else {
          promptEnabled.value = true;
        }
      }

      deletePromptInfo.enabled = false;
      loadUser();
    };

    const onChangeTab = (tab) => {
      activeTab.value = tab;
    };

    const onBackClicked = () => {
      router.push({
        query: {
          ...route.query,
          user: undefined,
        },
      });
    };

    const loadUserPreferences = async () => {
      await resetPreferences();
      if (product.value && product.value.code !== 'All products') {
        await fetchPreferences({ productCodes: product.value.code });
      } else if (products.value?.length) { // all products
        const productCodes = products.value.map((prod) => prod.code).join(',');
        await fetchPreferences({ productCodes });
      }
    };

    const loadActor = async () => {
      if (!actor.value) {
        const state = auth0;
        try {
          actor.value = await getUser({ userId: state.user.value['https://accounts.ikea.com/networkid'] });
        } catch (err) {
          promptTitle.value = '';
          promptInfo.value = 'Unable to get your acting user account.';
          promptEnabled.value = true;
        }
      }
    };

    const loadActorCanDelegateRolesAndPrivileges = async () => {
      actorCanDelegateRoles.value = []; // reset
      actorCanDelegatePrivileges.value = []; // reset
      try {
        actorCanDelegateRoles.value = await Promise.all((actor.value?.user?.roles || [])
          .filter((r) => r.canDelegate)
          .map(async (r) => {
            const payload = {
              hierarchyCode: r.hierarchyCode,
              hierarchyNodeCode: r.hierarchyNodeCode,
              hierarchyNodeType: r.hierarchyNodeType,
              ancestors: false,
            };
            const children = await getHierarchyNodes(payload);
            return {
              ...r,
              children,
            };
          }));
        actorCanDelegatePrivileges.value = await Promise.all((actor.value?.user?.privileges || [])
          .filter((p) => p.canDelegate)
          .map(async (p) => {
            const payload = {
              hierarchyCode: p.hierarchyCode,
              hierarchyNodeCode: p.hierarchyNodeCode,
              hierarchyNodeType: p.hierarchyNodeType,
              ancestors: false,
            };
            const children = await getHierarchyNodes(payload);
            return {
              ...p,
              children,
            };
          }));
      } catch (err) {
        promptTitle.value = '';
        promptInfo.value = 'Unable to get roles and privileges to delegate.';
        promptEnabled.value = true;
      }
    };

    const loadActorManageProducts = async () => {
      actorManageProducts.value = []; // reset
      let privilegeCode = null;
      switch (activeTab.value.name) {
        case 'Roles':
          privilegeCode = 'ManageAnyUserRoles';
          break;
        case 'Privileges':
          privilegeCode = 'ManageAnyUserPrivileges';
          break;
        case 'Preferences':
          privilegeCode = 'ManageAnyUserPreferences';
          break;
        default:
          privilegeCode = null;
      }
      const payload = {
        productCode: 'DFP',
        hierarchyNodeType: 'PRODUCT',
        privilegeCode,
        userId: actor.value?.user?.userId,
      };
      try {
        const nodes = await getHierarchyNodesForPrivilege(payload);
        actorManageProducts.value = nodes.hierarchyNodes.map((node) => ({
          name: node.hierarchyNodeName,
          code: node.hierarchyNodeCode,
        }));
      } catch (err) {
        promptTitle.value = '';
        promptInfo.value = 'Unable to get products to manage.';
        promptEnabled.value = true;
      }
    };

    const loadPrivilegeProducts = async () => {
      const filteredProducts = products.value.filter((p) => p.code !== 'All products');
      privilegeProducts.value = [];
      await Promise.all((product.value.code !== 'All products' ? [product.value] : filteredProducts).map(async (p) => {
        const payload = {
          productCode: 'DFP',
          preferenceCode: 'CanAssignPrivilegeToUser',
          hierarchyCode: 'Product',
          hierarchyNodeCode: p.code,
          hierarchyNodeType: 'PRODUCT',
        };
        const { hasPreferenceValue } = await getHasPreferenceValue(payload);
        if (hasPreferenceValue) {
          const { preferenceValues } = await getPreferenceValues(payload);
          if (parseInt(preferenceValues[0].value, 10)) {
            privilegeProducts.value.push(p.code);
          }
        }
      }));
    };

    watch(() => activeTab.value, async () => {
      await loadActor();
      await loadActorManageProducts();
    }, { immediate: true });

    watch(() => product.value, async () => {
      await loadUser();
      await loadActor();
      await loadPrivilegeProducts();
      await loadUserPreferences();
      if ((activeTab.value?.name === 'Privileges' && !privilegeProducts.value?.length)
        || (activeTab.value?.name === 'Preferences' && !preferences.value?.length)) {
        [activeTab.value] = tabs.value; // reset to Roles
      }
    });

    watch(() => route.query.user, async (userId) => {
      await loadUser(userId);
    }, { immediate: true });

    onMounted(async () => {
      await loadActor();
      await loadActorCanDelegateRolesAndPrivileges();
      await loadPrivilegeProducts();
      await loadUserPreferences();
    });

    return {
      toHash,
      product,
      user,
      activeTab,
      tabs,
      showRoleSheet,
      showPrivilegeSheet,
      showPreferenceSheet,
      privilegeProducts,
      canAddRole,
      canAddPrivilege,
      actorCanDelegateRoles,
      actorCanDelegatePrivileges,
      actorManageProducts,
      isEditablePreference,
      getNodeAttribute,
      getUserPreference,
      userPreferenceRows,
      preferenceToEdit,
      userPrivilegeRows,
      onChangeTab,
      onBackClicked,
      onAddRoleClicked,
      onAddPrivilegeClicked,
      onEditPreferenceClicked,
      onUserRoleAdded,
      onUserPrivilegeAdded,
      onUserPreferenceEdited,
      promptEnabled,
      promptTitle,
      promptInfo,
      deletePromptInfo,
      onDeletePromptConfirmClicked,
      rolesTableHeaders,
      onCanDelegateRoleClicked,
      onCanDelegatePrivClicked,
      canDelegateRole,
      canDelegatePrivilege,
      onDeleteRoleClicked,
      onDeletePrivClicked,
      canManageProduct,
      privilegeTableHeaders,
      userRoleRows,
      pencil: Icons.pencil,
      trashCan: Icons.trashCan,
      plus: Icons.plus,
      arrowLeft: Icons.arrowLeft,
    };
  },
};
</script>

<style scoped>
.user__back {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.625rem 0 0.3125rem;
}

.user__name_inactive {
  display: flex;
  align-items: center;
}

.user__info {
  color: var(--colour-text-lightest);
}

.user__info > span {
  padding: 0 0.625rem;
  border-right: 1px solid var(--colour-border-default);
}

.user__info > span:first-child {
  padding-left: 0;
}

.user__info > span:last-child {
  padding-right: 0;
  border: none;
}

.user__tabs {
  margin-top: 2.125rem;
}

.user__tab-title {
  font-size: 1.5rem;
  font-weight: var(--font-weight-bold);
}

.user__tab-title-button {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  margin-bottom: 0.875rem;
}

.user__inactive {
  height: 2.125rem;
  padding: 0.25rem 0.4375rem;
  margin-left: 0.5rem;
  font-size: 1rem;
  color: var(--colour-text-inverse);
  background-color: var(--colour-semantic-negative);
  border-radius: 0.25rem;
}

.user__checkbox-wrapper {
  display: flex;
  justify-content: center;
  padding-top: 0.75rem;
}

.user__delete-button {
  padding-top: 0.25rem;
}

.user__content {
  padding-right: 0.5rem;
  overflow-y: auto;
}

.user__privilege-table,
.user__preference-table {
  overflow-x: auto;
}

.table__data .helper {
  color: var(--colour-text-lightest);
}

.user__node-hierarchy {
  color: var(--colour-text-lightest);
}

.user__checkbox {
  display: flex;
  justify-content: center;
}

.user__no-privileges,
.user__no-preferences,
.user__no-roles {
  display: flex;
  justify-content: center;
  padding-top: 4rem;
  color: var(--colour-text-lightest);
}
</style>
